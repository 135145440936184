import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { LoadingSpinner } from '../../shared/loading-spinner/loading-spinner';

type ProtectedRouteProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.ComponentType<any>;
	path: string;
	exact?: boolean;
};

export const ProtectedRoute = ({ component, path, exact }: ProtectedRouteProps): JSX.Element => <Route
	component={withAuthenticationRequired(
		component,
		{
			// eslint-disable-next-line react/display-name
			onRedirecting: () => <LoadingSpinner/>
		}
	)}
	exact={exact}
	path={path}
/>;
