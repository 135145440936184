import {
	Box,
	Container,
	Flex,
	FormLabel,
	Input,
	Select,
	Stack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import List from './list/list';
import { LoadingSpinner } from '../../shared/loading-spinner/loading-spinner';
import { selectCustomers } from '../../../store/customers/select-customers.selector';

import './customers.scss';
import { fetchCustomers } from '../../../store/customers/fetch-customers.action';

export const Customers = ({ match }: any): JSX.Element => {
	const { email } = match.params;
	const [searchString, setSearchString] = useState<string>(email || '');
	const [days, setDays] = useState<string>('0');

	const dispatch = useDispatch();

	const customersState = useSelector(selectCustomers);
	const { loading, data } = customersState ? customersState : { loading: false, data: [] };
	const showList = customersState.data.length > 0;

	useEffect(() => {
		dispatch(fetchCustomers(searchString, days));
	}, [searchString, days]);

	const performSearch = (e) => {
		if (e.key === 'Enter') {
			setSearchString(e.target.value);
		}
	};

	return (
		<Flex width="full" align="center" justifyContent="center" className="TSes">
			<Container maxWidth="80ch">
				<Stack spacing={5}>
					<Input variant="flushed" className={`initial ${showList ? 'after' : ''}`}
						placeholder="Search a customer by email or name." onKeyDown={performSearch}
					/>
					<Box display="Flex">
						<FormLabel htmlFor="limit-days" margin="auto 0">No Older Than:&nbsp;</FormLabel>
						<Select id="limit-days"
							width="200px"
							value={days}
							onChange={(event) => setDays(event.target.value)}
						>
							<option value={'0'}>All Record</option>
							<option value={'30'}>30 Days</option>
							<option value={'60'}>60 Days</option>
							<option value={'90'}>90 Days</option>
							<option value={'180'}>180 Days</option>
							<option value={'365'}>365 Days</option>
						</Select>
					</Box>
					{!loading && <List customers={data} />}
				</Stack>
				<Box width="100%" margin="50px auto">
					{(loading) && <LoadingSpinner />}
				</Box>

			</Container>
		</Flex>
	);
};

