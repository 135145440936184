import { gql } from '@apollo/client';

export const GetCustomersListBySearchTerm = gql`
	query fuzzySearchCustomers($searchString: String!, $days: Float){
       fuzzySearchCustomers(searchString: $searchString, days: $days){
           etag
           partitionKey
           rowKey
           timestamp
           firstName
           lastName
           email
           numberOfDesigns
           pathToMetadataFile
       }
}
`;

// export const GetCustomersListBySearchTerm = gql`
//    query fuzzySearchCustomers($searchString: String!){
//        fuzzySearchCustomers(searchString: $searchString){
//            etag
//            partitionKey
//            rowKey
//            timestamp
//            firstName
//            lastName
//            email
//            numberOfDesigns
//            pathToMetadataFile
//            designs
//            blueprints {
//                isPrimary
//                email
//                image
//                date
//                layout
//                islands
//                state
//                surround
//                counter
//                estimateNumber
//                invoiceNumber
//                workorderNumber
//            }
//        }
// }
// `;
