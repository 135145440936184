import { Reducer } from 'redux';
import { action } from 'typesafe-actions';

import { CustomersActionTypes, CustomersState, customersStateInitialState } from '.';

// eslint-disable-next-line default-param-last
const reducer: Reducer<CustomersState> = (state: CustomersState = customersStateInitialState, action) => {
	switch (action.type) {
		case CustomersActionTypes.FETCH_REQUEST: {
			return { ...state, loading: true };
		}
		case CustomersActionTypes.FETCH_SUCCESS: {
			return { ...state, loading: false, data: action.payload };
		}
		case CustomersActionTypes.FETCH_ERROR: {
			return { ...state, loading: false, errors: action.payload };
		}
		case CustomersActionTypes.FETCH_DESIGNS_REQUEST: {
			return { ...state };
		}
		case CustomersActionTypes.FETCH_DESIGNS_SUCCESS: {
			const newState = {
				...state,
				data: state.data.map((customer) => {
					if (customer.email === action.payload[0].email) {
						customer.designs = action.payload[0].designs;
						customer.blueprints = action.payload[0].blueprints ?? [];
					}
					return customer;
				})
			};
			console.log('FETCH_DESIGNS_SUCCESS', newState.data.filter((c) => c.email === action.payload[0].email), action.payload[0]);
			return newState;
		}
		case CustomersActionTypes.FETCH_DESIGNS_ERROR: {
			return { ...state, errors: action.payload };
		}
		default: {
			return state;
		}
	}
};

export { reducer as customersReducer };
