/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

import { BlueprintActionTypes } from '.';
import { GetCustomerBlueprint } from '../../graph/queries';
import { Blueprint, BlueprintRaw, processSchema } from '@rta/rta-blueprint-component';

export const fetchBlueprintRequest = () => action(BlueprintActionTypes.FETCH_REQUEST);
export const fetchBlueprintSuccess = (data: Blueprint) => action(BlueprintActionTypes.FETCH_SUCCESS, data);
export const fetchBlueprintError = (message: string) => action(BlueprintActionTypes.FETCH_ERROR, message);

export const fetchCustomerBlueprint = (email: string, designName: string) => (dispatch, getState, { client }) => {
	dispatch(fetchBlueprintRequest());

	return client
		.query(
			'getCustomerBlueprintToView',
			GetCustomerBlueprint,
			{ email, designName }
		)
		.then((data: BlueprintRaw) => {
			const patchedData = processSchema(data);
			return dispatch(fetchBlueprintSuccess(patchedData));
		})
		.catch((e) => dispatch(fetchBlueprintError(e.message)));
};
