import { Blueprint } from '@rta/rta-blueprint-component';

export type GraphResponse = Record<string, Blueprint>;

export enum BlueprintActionTypes {
	FETCH_REQUEST = '@@blueprint/FETCH_REQUEST',
	FETCH_SUCCESS = '@@blueprint/FETCH_SUCCESS',
	FETCH_ERROR = '@@blueprint/FETCH_ERROR',
	UPDATE_CUSTOMER_BLUEPRINT_REQUEST = '@@blueprint/UPDATE_CUSTOMER_BLUEPRINT_REQUEST',
	UPDATE_CUSTOMER_BLUEPRINT_SUCCESS = '@@blueprint/UPDATE_CUSTOMER_BLUEPRINT_SUCCESS',
	UPDATE_CUSTOMER_BLUEPRINT_ERROR = '@@blueprint/UPDATE_CUSTOMER_BLUEPRINT_ERROR',
	SAVE_BLUEPRINT_REQUEST = '@@blueprint/SAVE_BLUEPRINT_REQUEST',
	SAVE_BLUEPRINT_SUCCESS = '@@blueprint/SAVE_BLUEPRINT_SUCCESS',
	SAVE_BLUEPRINT_ERROR = '@@blueprint/SAVE_BLUEPRINT_ERROR',
	SAVE_MANUFACTURING_BLUEPRINT_REQUEST = '@@blueprint/SAVE_MANUFACTURING_BLUEPRINT_REQUEST',
	SAVE_MANUFACTURING_BLUEPRINT_SUCCESS = '@@blueprint/SAVE_MANUFACTURING_BLUEPRINT_SUCCESS',
	SAVE_MANUFACTURING_BLUEPRINT_ERROR = '@@blueprint/SSAVE_MANUFACTURING_BLUEPRINT_ERROR'
}

export interface BlueprintState {
	readonly loading: boolean;
	readonly saving: boolean;
	readonly data: Blueprint;
	readonly errors?: string;
}
