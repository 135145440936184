import { useState } from 'react';

import { PGCountertop } from './pg-countertop';
import { PGPanel } from './pg-panel';
import { PGProps } from '../print-types';

import '../../../../styles/designer-page.scss';
import '../print.scss';

export const PGPanels = (props: PGProps): JSX.Element => {
	const { blueprint } = props;
	const activeStateIndex = blueprint.state.length - 1;
	const [bpdState, setBpdState] = useState({} as unknown);

	return <>{
		blueprint.state[activeStateIndex].islands.map((island) => {
			return [
				<PGCountertop key="99" {...props} islandKey={island.key}/>,
				island.panels.map((panel, panelIndex) => {
					return <PGPanel key={panelIndex} {...props} islandKey={island.key} panelIndex={panelIndex}/>;
				})
			];
		})
	}</>;
};
