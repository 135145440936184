/* eslint-disable max-lines-per-function */
import { useSelector } from 'react-redux';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Spinner,
	Stack,
	Text
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';

import { BlueprintImage } from './blueprint-image/blueprint-image';
import { Customer, selectCustomers } from '../../../../../store/customers';

type ItemsProps = {
	customerData: Customer
};

export const Item = ({ customerData }: ItemsProps): JSX.Element => {
	const customersState = useSelector(selectCustomers);
	// const customer = JSON.parse(JSON.stringify(customerData));
	const [customer] = customersState.data.filter((c) => c.email === customerData.email) as Array<any>;
	const loading = (customer.blueprints ?? []).length === 0;

	return (
		<AccordionItem>
			<h2>
				<AccordionButton _expanded={{ bg: 'lightblue', color: 'white' }}>

					<Flex align="space-between" direction="row" w="100%">
						<Box w="70%" textAlign="left">{customer.firstName} {customer.lastName}</Box>
					</Flex>
					<AccordionIcon/>
				</AccordionButton>
			</h2>
			<AccordionPanel>
				{ customer && console.log('item:accordian', loading, customer.blueprints, customer.blueprint ?? [])}
				<Flex align="start" direction="column" color="white">
					<Box background="grey" width="100%" marginBottom="16px">
						<Flex align="center" direction="row" justify="space-around" width="100%">
							<Stack isInline align="center">
								<EmailIcon margin="0 10px 0 0"/>
								<Text fontSize="lg"> {customer.email ?? 'no email'}</Text>
							</Stack>

							<Stack isInline align="center">
								<PhoneIcon margin="0 10px 0 0"/>
								<Text fontSize="lg">{customer.phone ?? 'not on file'}</Text>
							</Stack>
						</Flex>
					</Box>
					{ loading &&
						<Box width="100%" height="30px"><Spinner color="black"/></Box>
					}
					{ !loading &&
						<Stack isInline align="start" justify="space-between" width="100%" wrap="wrap">
							{(customer.blueprints ?? []).map((blueprint, i) => <BlueprintImage blueprint={blueprint} customer={customerData} key={i}/>)}
						</Stack>
					}
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
};

