/* eslint-disable max-lines-per-function */
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
	Box,
	Button,
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	Tooltip,
	useDisclosure
} from '@chakra-ui/react';
import { ChevronDownIcon, DownloadIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { Customer } from '../../../../../../store/customers/types';
import { LoadingSpinner } from '../../../../../shared/loading-spinner/loading-spinner';
import { isSavingBlueprint, updateCustomerBlueprint } from '../../../../../../store/blueprint';

import placeholderImage from '../../../../../../assets/images/blueprint-missing.png';

type BlueprintImageProps = {
	blueprint: {
		estimateNumber: string;
		invoiceNumber: string;
		workorderNumber: string;
		date: string;
		image: string;
	},
	customer: Customer;
};

export const BlueprintImage = ({
	blueprint: { estimateNumber, invoiceNumber, workorderNumber, date, image },
	customer
}: BlueprintImageProps): JSX.Element => {
	const history = useHistory();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [editableFields, setEditableFields] = useState({ estimateNumber, invoiceNumber, workorderNumber });
	const [editableChangedFields, setChangedEditableFields] = useState({ estimateNumber, invoiceNumber, workorderNumber });
	const [isImageHovered, setIsImageHovered] = useState(false);

	const dispatch = useDispatch();
	const isSavingChanges = useSelector(isSavingBlueprint);

	const [isHoveredSectionExpanded, setIsHoveredSectionExpanded] = useState(false);

	const usePlaceholderImage = image === null;
	const useImageUrl = !usePlaceholderImage && image.length < 1000;
	const imageData = useImageUrl ? `${process.env.REACT_APP_BLUEPRINT_IMAGES_BASE_URL}/${image}` : `data:image/jpeg;base64,${image}`;
	const imageSrc = usePlaceholderImage ? placeholderImage : imageData;

	return (
		<Box width="23ch" position="relative" marginBottom="100px">
			{isImageHovered &&	<Flex
				width="100%"
				height="100%"
				backgroundColor="rgba(0, 0, 0, 0.4)"
				zIndex="999" pos="absolute" top="0" left="0" align="center" justify="space-between"
				direction="column"
				onMouseLeave={() => {
					setIsImageHovered(false);
					setIsHoveredSectionExpanded(false);
				}}
			>
				<Box margin="20px auto" width="100%">
					<Tooltip label="View Blueprint">
						<ViewIcon height="2em" margin="0 20px 0 0" width="20%" opacity="1" cursor="pointer"
							onClick={() => {
								history.push(`/blueprint/view/${customer.email}/${date}/`);
							}}/>
					</Tooltip>

					<Tooltip label="Print">
						<DownloadIcon height="2em" margin="0 0 0 0" width="20%" opacity="1" cursor="pointer"
							onClick={() => {
								history.push(`/blueprint/print/${customer.email}/${date}/`);
							}}/>
					</Tooltip>

					<Tooltip label="Edit Blueprint">
						<EditIcon height="2em" margin="0 0 0 20px" width="20%" opacity="1" cursor="pointer"
							onClick={() => {
								history.push(`/blueprint/edit/${customer.email}/${date}/`);
							}}/>
					</Tooltip>
				</Box>

				<Box backgroundColor="grey" width="100%">
					<Stack isInline align="center" paddingLeft="16px">
						<Text fontSize="md">BluePrint for</Text>
						<Text fontSize="md">{customer.firstName} {customer.lastName}</Text>
					</Stack>
					<Stack isInline align="center" paddingLeft="16px">
						<Text fontSize="xs">Submitted on </Text>
						<Text fontSize="xs">{moment(Number(date)).format('MM/DD/YYYY h:mmA')}</Text>
					</Stack>
					{isHoveredSectionExpanded
						? <Box>
							<Flex align="start" direction="column" justify="start" width="100%" paddingLeft="16px">
								<Stack isInline align="center" paddingLeft="16px">
									<Text fontSize="xs">Invoice #: </Text>
									<Text fontSize="xs">{editableFields.invoiceNumber}</Text>
								</Stack>
								<Stack isInline align="center" paddingLeft="16px">
									<Text fontSize="xs">Estimate #: </Text>
									<Text fontSize="xs">{editableFields.estimateNumber}</Text>
								</Stack>

								<Stack isInline align="center" paddingLeft="16px">
									<Text fontSize="xs">Work Order #: </Text>
									<Text fontSize="xs">{editableFields.workorderNumber}</Text>
								</Stack>

								<Stack align="center" width="100%">
									<Button colorScheme="telegram" onClick={onOpen}>Edit</Button>
									<Modal isOpen={isOpen} onClose={onClose}>
										<ModalOverlay/>
										<ModalContent>
											<ModalHeader>Blueprint Metadata</ModalHeader>
											<ModalCloseButton/>
											<ModalBody>
												<Stack isInline align="center">
													<Text fontSize="xs" marginTop="5px">Invoice #: </Text>
													<Editable
														defaultValue={editableChangedFields.invoiceNumber ? editableChangedFields.invoiceNumber : 'click to edit'}
														onChange={(value) => {
															setChangedEditableFields({
																...editableChangedFields,
																invoiceNumber: value
															});
														}}>
														<EditablePreview fontSize="xs"/>
														<EditableInput fontSize="xs"/>
													</Editable>
												</Stack>

												<Stack isInline align="center">
													<Text fontSize="xs" marginTop="5px">Estimate #: </Text>
													<Editable
														defaultValue={editableChangedFields.estimateNumber ? editableChangedFields.estimateNumber : 'click to edit'}
														onChange={(value) => {
															setChangedEditableFields({
																...editableChangedFields,
																estimateNumber: value
															});
														}}>
														<EditablePreview fontSize="xs"/>
														<EditableInput fontSize="xs"/>
													</Editable>
												</Stack>

												<Stack isInline align="center">
													<Text fontSize="xs" marginTop="5px">Work Order #: </Text>
													<Editable
														defaultValue={editableChangedFields.workorderNumber ? editableChangedFields.workorderNumber : 'click to edit'}
														onChange={(value) => {
															setChangedEditableFields({
																...editableChangedFields,
																workorderNumber: value
															});
														}}>
														<EditablePreview fontSize="xs"/>
														<EditableInput fontSize="xs"/>
													</Editable>
												</Stack>
											</ModalBody>

											<ModalFooter>
												{isSavingChanges
													? <LoadingSpinner/>
													: <>
														<Button variant="ghost" mr={3}
															onClick={
																() => {
																	dispatch(updateCustomerBlueprint(editableChangedFields));
																	setEditableFields(editableChangedFields);
																	onClose();
																	setIsHoveredSectionExpanded(false);
																}}>
																Save
														</Button>

														<Button variant="blue" size="xs" m="5px 0" onClick={() => {
															setChangedEditableFields(editableChangedFields);
															onClose();
															setIsHoveredSectionExpanded(false);
														}}>Cancel</Button>
													</>}
											</ModalFooter>
										</ModalContent>
									</Modal>

								</Stack>
							</Flex>
						</Box>
						: <Box>
							<ChevronDownIcon height="2em" margin="0 20px 0 0" width="20%" opacity="1" cursor="pointer"
								onClick={() => setIsHoveredSectionExpanded(true)} />
						</Box>}
				</Box>

			</Flex>}
			<img src={imageSrc} style={{ border: '1px rgb(50,50,50,0.1) solid' }}
				onMouseEnter={() => {
					setIsImageHovered(true);
				}}
			/>
		</Box>
	);
};
