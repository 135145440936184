import { FaMoon, FaSun } from 'react-icons/fa';
import {
	IconButton,
	IconButtonProps,
	useColorMode,
	useColorModeValue
} from '@chakra-ui/react';

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
	const { toggleColorMode } = useColorMode();
	const text = useColorModeValue('dark', 'light');
	const SwitchIcon = useColorModeValue(FaMoon, FaSun);

	return (
		<IconButton
			size="sm"
			// margin="0 0 10% 0"
			fontSize="md"
			variant="ghost"
			color="current"
			// marginLeft="2"
			onClick={toggleColorMode}
			icon={<SwitchIcon />}
			aria-label={`Switch to ${text} mode`}
			{...props}
		/>
	);
};
