import QRCode from 'qrcode.react';

import {
	Box,
	Flex,
	Img,
	Text
} from '@chakra-ui/react';
import { GroupAppliances, groupAppliances } from '@rta/rta-blueprint-component';

import { appliancePage } from './pg-appliance';
import { PGProps } from '../print-types';

import '../../../../styles/designer-page.scss';

export const PGAppliances = (props: PGProps): JSX.Element => {
	const { blueprint, customer, designerOptions } = props;
	const { state } = blueprint;
	const activeStateIndex = state.length - 1;
	const applianceGrouping = activeStateIndex >= 0
		? groupAppliances(state[activeStateIndex].islands) as GroupAppliances
		: {
			appliances: {},
			numAppliances: 0,
			numApplianceTypes: 0
		};
	const numPages = Math.ceil(applianceGrouping.numApplianceTypes / 5);

	const renderHead = [
		{ name: 'Item & Description' },
		{ name: 'Qty' },
		{ name: 'Rate' }
	];

	const renderCell = [
		{
			render: (sku: string) => {
				return (
					<Flex>
						<Img src={`${designerOptions.assetsBaseUrl}/products/${sku}.png${designerOptions.assetsSasToken}`} width="60px" height="60px" objectFit="scale-down" marginRight="16px" />
						<Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
							<Text fontSize="14px" color="black" textAlign="right">{sku}</Text>
							<Text fontSize="14px" color="black" textAlign="right">{applianceGrouping.appliances[sku].description}</Text>
						</Flex>
					</Flex>
				);
			}
		},
		{
			render: (sku: string) => {
				return (
					<Text fontSize="14px" color="black" textAlign="right">{applianceGrouping.appliances[sku].quantity}</Text>
				);
			}
		},
		{
			cellClass: 'table-qrcode',
			render: (sku: string) => {
				return (
					<Box position="absolute" height="60px" left="50%" top="50%" transform="translate(-50%, -50%)">
						<QRCode level="Q" value={JSON.stringify({ sku })} />
					</Box>
				);
			}
		}
	];

	const pages: Array<JSX.Element> = [];
	for (let i = 0; i < numPages; i++) {
		pages.push(appliancePage(({
			pageNum: i,
			applianceGrouping: applianceGrouping as GroupAppliances,
			renderHead,
			renderCell,
			blueprint,
			customer
		})));
	}

	return <>{pages}</>;
};
