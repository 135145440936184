/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { GetCustomersListBySearchTerm } from '../../graph/queries/get-customers-list-by-search-term';
import { Customer, CustomersActionTypes } from './types';

export const fetchCustomersRequest = () => action(CustomersActionTypes.FETCH_REQUEST);
export const fetchCustomersSuccess = (data: Array<Customer>) => action(CustomersActionTypes.FETCH_SUCCESS, data);
export const fetchCustomersError = (message: string) => action(CustomersActionTypes.FETCH_ERROR, message);

export const fetchCustomers = (searchString: string, days = '0') => (dispatch, getState, { client }) => {
	dispatch(fetchCustomersRequest());

	// empty search string clears results
	if (searchString === '') {
		return new Promise((resolve) => {
			resolve(dispatch(fetchCustomersSuccess([])));
		});
	}

	return client
		.query(
			'fuzzySearchCustomers',
			GetCustomersListBySearchTerm,
			{ searchString, days: Number(days) }
		)
		.then((data: Array<Customer>) => dispatch(fetchCustomersSuccess(data)))
		.catch((e) => dispatch(fetchCustomersError(e.message)));
};
