/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

import { AddManufacturingBlueprint } from '../../graph/mutations';
import { Blueprint } from '@rta/rta-blueprint-component';
import { BlueprintActionTypes, BlueprintState } from '.';

export const saveManufacturingBlueprintRequest = () => action(BlueprintActionTypes.SAVE_MANUFACTURING_BLUEPRINT_REQUEST);
export const saveManufacturingBlueprintSuccess = (data: Blueprint) => action(BlueprintActionTypes.SAVE_MANUFACTURING_BLUEPRINT_SUCCESS, data);
export const saveManufacturingBlueprintError = (message: string) => action(BlueprintActionTypes.SAVE_MANUFACTURING_BLUEPRINT_ERROR, message);

export const addManufacturingBlueprint = () => (dispatch, getState, { client }) => {
	dispatch(saveManufacturingBlueprintRequest());

	const blueprintState: BlueprintState = getState().blueprint;
	const { email } = blueprintState.data;
	// don't send image data, it's immutable
	const blueprintId = blueprintState.data.date;

	return client
		.mutate(
			'addManufacturingBlueprint',
			AddManufacturingBlueprint,
			{ email, blueprintId }
		)
		.then((data: Blueprint) => {
			console.log('Manufacturing Blueprint sent to Manufacturing Application...');
			return dispatch(saveManufacturingBlueprintSuccess({ ...blueprintState.data, ...data }));
		})
		.catch((e) => dispatch(saveManufacturingBlueprintError(e.message)));
};
