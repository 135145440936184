/* eslint-disable no-alert */
import { Accordion } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { Item } from './item/item';
import { Customer, fetchCustomerDesigns } from '../../../../store/customers';

type ListProps = {
	customers: Array<Customer>
};

const updateAccordian = (customer, dispatch) => {
	dispatch(fetchCustomerDesigns(customer.email));
	return true;
};

const List = ({ customers }: ListProps): JSX.Element => {
	const dispatch = useDispatch();

	return (
		<Accordion onChange={(e) => updateAccordian(customers[e as number], dispatch)}>
			{customers.map((item, i) => <Item customerData={item} key={i}/>)}
		</Accordion>
	);
};

export default List;
