/* eslint-disable no-magic-numbers */
import { FaHome } from 'react-icons/fa';
import { Icon } from '@chakra-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Box, Tooltip } from '@chakra-ui/react';

import { ColorModeSwitcher } from '../color-mode/color-mode-switcher';
import { HeaderLogo } from './header-logo';
import { LoginButton } from '../login-button/login-button';
import { LogoutButton } from '../logout-button/logout-button';

export const Header = (): JSX.Element => {
	const { isAuthenticated } = useAuth0();
	const history = useHistory();

	const showHomeIcon = !history.location.pathname.includes('/view/');
	// console.log('history', history, showHomeIcon);
	return (
		<Box className="noprint" alignItems="baseline" py={4} bg={'#252525'} width={'100%'} color={'white'}>
			<Box padding="0 0 0 3%" verticalAlign="left" w={['20%', '30%', '50%', '50%']} display="inline-block">
				<HeaderLogo />
				{showHomeIcon && <Tooltip label="Search Blueprints">
					<Icon as={FaHome} height="2em" margin="0 0 0 20px" width="20%" opacity="1" cursor="pointer"
						onClick={() => {
							history.push('/');
						}}/>
				</Tooltip>}
			</Box>
			<Box verticalAlign="right" w={['80%', '70%', '50%', '50%']} display="inline-block" justifySelf="flex-end">
				<Box mr={[2, 2, 4, 5]} display="inline-block" fontSize={['16px', '18px', '18px', '18px']} >
					1-800-827-1830
				</Box>
				<Box display="inline-block" mr={[2, 2, 4, 5]}><ColorModeSwitcher /></Box>
				<Box display="inline-block" mr={[4, 6, 10, 10]} >
					{isAuthenticated ? <LogoutButton /> : <LoginButton />}
				</Box>
			</Box>

		</Box>
	);
};
