import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
	Button,
	Flex
} from '@chakra-ui/react';
import {
	defaultPublicState,
	DesignerOptionsState
} from '@rta/rta-blueprint-component';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { PGAppliances } from './pg-appliances';
import { PGCover } from './pg-cover';
import { PGCrateSheet } from './pg-crate-sheet';
import { PGOverview } from './pg-overview';
import { PGPanels } from './pg-panels';

import { CustomersState } from '../../../../store/customers/types';
import { fetchCustomers } from '../../../../store/customers';
import { LoadingSpinner } from '../../../shared/loading-spinner/loading-spinner';
import { selectBlueprint } from '../../../../store/blueprint/select-blueprint.selector';
import { selectCustomers } from '../../../../store/customers/select-customers.selector';
import { BlueprintState, fetchCustomerBlueprint } from '../../../../store/blueprint';

import '../../../../styles/designer-page.scss';
import '../print.scss';

type PrintProps = {
	match: {
		params: {
			email: string
			date: string
		}
	}
}

export const Print = (props: PrintProps): JSX.Element => {
	const { email, date } = props.match.params;

	const blueprintState: BlueprintState = useSelector(selectBlueprint);
	const customersState: CustomersState = useSelector(selectCustomers);
	const { isAuthenticated } = useAuth0();
	const dispatch = useDispatch();
	const history = useHistory();
	const componentRef = useRef<HTMLDivElement>(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});

	useEffect(() => {
		dispatch(fetchCustomerBlueprint(email, date));
		dispatch(fetchCustomers(email));
	}, [email, date]);

	const [customer] = customersState.data;
	const { state } = blueprintState.data;
	const activeStateIndex = state.length - 1;

	// Base Blueprint Designer Options
	const designerOptions: DesignerOptionsState = {
		...defaultPublicState.options,
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		assetsBaseUrl: process.env.REACT_APP_ASSETS_BASE_URL,
		assetsSasToken: process.env.REACT_APP_ASSETS_SAS_TOKEN,
		readOnly: true,
		showTitle: false,
		showZoom: false,
		showDisplayMode: false,
		appliance: {
			showLabel: true,
			fontSizeFactor: 1.5
		},
		dimensions: {
			fontSizeFactor: 2
		}
	};

	// Base QRCode data
	const qrData = activeStateIndex < 0 || !customer
		? {}
		// : 'test';
		: {
			customer: {
				email: customer.email
			},
			blueprint: {
				design: blueprintState.data.date
			},
			metadata: {
				stateIndex: state.length - 1
			}
		};

	const pageParams = {
		designerOptions,
		blueprint: blueprintState.data,
		customer,
		qrData
	};

	const isLoading = blueprintState.loading;
	return (isLoading || !blueprintState || !customer || activeStateIndex < 0
		? <LoadingSpinner />
		: <>
			{isAuthenticated &&
			<Flex direction="column">
				<Flex className="noprint" alignItems="start">
					<Button size="xs" margin="20px 0 0 20px" onClick={() => {
						history.push('/');
					}}>
					Back to list of customers
					</Button>
					<Button size="xs" margin="20px 0 0 20px" onClick={handlePrint}>
					Print
					</Button>
				</Flex>

				<div className="pages" ref={componentRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					<PGCover {...pageParams}/>
					<PGOverview {...pageParams}/>
					<PGPanels {...pageParams}/>
					<PGAppliances {...pageParams}/>
					<PGCrateSheet {...pageParams}/>
				</div>

				{/* {renderViews.map((_, key) => <BlueprintDesigner onStateChange={() => defaultDesignerState} onReady={renderViews[key]} key={key} />)} */}
			</Flex>
			}
		</>);
};
