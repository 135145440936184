import { PaymentStatus } from '@rta/rta-blueprint-component';

import { BlueprintState } from './types';

export const initialState: BlueprintState = {
	data: {
		schemaVersion: null,
		email: '',
		date: '',
		isPrimary: false,
		// layout: '',
		estimateNumber: '',
		invoiceNumber: '',
		workorderNumber: '',
		// fuelchoice: '',
		// surroundMaterial: '',
		// counter: '',
		paymentStatus: PaymentStatus.NotPaid,
		islands: [],
		state: []
	},
	loading: false,
	saving: false
};

export { initialState as blueprintStateInitialState };
