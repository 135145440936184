import { Image } from '@chakra-ui/react';
import logoWhite from '../../../assets/images/logo-white.svg';

export const HeaderLogo = (): JSX.Element => <Image
	display="inline-block"
	margin="0 0 0 0"
	htmlWidth="150px"
	htmlHeight="50px"
	objectFit="cover"
	src={ logoWhite }
	alt="RTA Outdoor Solutions Logo"
/>;
