import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

type Auth0ProviderWithHistoryProps = {
	children: React.ReactNode
};

export const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps): JSX.Element => {
	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
	const history = useHistory();

	const onRedirectCallback = (appState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={domain ? domain : ''}
			clientId={clientId ? clientId : ''}
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			audience={audience}
			advancedOptions={{
				defaultScope: 'openid profile email'
			}}
		> {children} </Auth0Provider>
	);
};
