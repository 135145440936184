import { useState } from 'react';
import {
	BlueprintDesigner,
	DesignerActionTypes,
	DesignerOptionsState,
	DisplayMode,
	NavigationActionTypes,
	PublicActionTypes,
	PublicState,
	ShowDimensions
} from '@rta/rta-blueprint-component';
import {
	Box,
	Text,
	VStack
} from '@chakra-ui/react';

import { PageHeader } from './pg-header';
import { PGProps } from '../print-types';
import { PageOrientation, Paper } from '../paper';

import '../../../../styles/designer-page.scss';

export const PGOverview = (props: PGProps): JSX.Element => {
	const { customer, blueprint, designerOptions } = props;
	const [bpdState, setBpdState] = useState({} as PublicState);

	const overviewDesignOptions: Partial<DesignerOptionsState> = {
		...designerOptions,
		showDimensions: ShowDimensions.None,
		blueprintToApplianceView: 100,
		autoZoom: true,
		zoomFactor: 1.8
	};

	const renderOverview = [
		async (bpDispatch: <t = unknown>(type: PublicActionTypes, payload: t) => void) => {
			await bpDispatch(NavigationActionTypes.SetDisplayMode, DisplayMode.Overhead);
			await bpDispatch(DesignerActionTypes.SetBlueprint, { ...blueprint });
		}
	];

	if (!props.blueprint || !props.customer) return <></>;

	return (
		<Paper orientation={ PageOrientation.landscape }>
			<VStack spacing={0} align="stretch" width="100%">
				<PageHeader blueprint={blueprint} customer={customer}/>
				<Box margin="10px 0">
					<Text fontSize="25px" textTransform="uppercase" color="black" textAlign="center"><strong>Top-Down Overview</strong></Text>
				</Box>
				<Box height="13cm" width="26.8cm">
					<BlueprintDesigner
						designerOptions={overviewDesignOptions}
						onStateChange={setBpdState}
						onReady={renderOverview[0]} />
				</Box>
			</VStack>
		</Paper>
	);
};
