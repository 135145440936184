import { Reducer } from 'redux';

import { BlueprintActionTypes, BlueprintState, blueprintStateInitialState } from '.';

// eslint-disable-next-line default-param-last
const reducer: Reducer<BlueprintState> = (state: BlueprintState = blueprintStateInitialState, action) => {
	switch (action.type) {
		case BlueprintActionTypes.FETCH_REQUEST: {
			return { ...state, loading: true };
		}
		case BlueprintActionTypes.FETCH_SUCCESS: {
			return { ...state, loading: false, data: action.payload };
		}
		case BlueprintActionTypes.FETCH_ERROR: {
			return { ...state, loading: false, errors: action.payload };
		}
		case BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_REQUEST: {
			return { ...state, saving: true };
		}
		case BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_SUCCESS: {
			return { ...state, saving: false, data: action.payload };
		}
		case BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_ERROR: {
			return { ...state, saving: false, errors: action.payload };
		}
		case BlueprintActionTypes.SAVE_BLUEPRINT_REQUEST: {
			return { ...state, saving: true };
		}
		case BlueprintActionTypes.SAVE_BLUEPRINT_SUCCESS: {
			return { ...state, saving: false, data: action.payload };
		}
		case BlueprintActionTypes.SAVE_BLUEPRINT_ERROR: {
			return { ...state, saving: false, errors: action.payload };
		}
		default: {
			return state;
		}
	}
};

export { reducer as blueprintReducer };
