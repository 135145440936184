/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import {
	BlueprintDesigner,
	blueprintDesignerAction,
	defaultPublicState,
	DesignerActionTypes,
	DesignerOptionActionTypes,
	DesignerOptionsState,
	DisplayMode,
	NavigationActionTypes,
	PublicState,
	ShowDimensions
} from '@rta/rta-blueprint-component';
import {
	Box,
	Center, Checkbox,
	Flex,
	Grid,
	GridItem,
	IconButton,
	Image,
	Img,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Text,
	useDisclosure
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';


import { LoadingSpinner } from '../../shared/loading-spinner/loading-spinner';
import { selectBlueprint } from '../../../store/blueprint/select-blueprint.selector';
import { BlueprintState, fetchCustomerBlueprint } from '../../../store/blueprint';

import '../../../styles/designer-page.scss';

export const View = ({ match }: { match: { params: { email: string; date: string; }}}): JSX.Element => {
	const { email, date } = match.params;

	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const blueprintState: BlueprintState = useSelector(selectBlueprint);

	// READ-ONLY -> this state is set through the designer tool
	const [designerState, setDesignerState] = useState<PublicState>(defaultPublicState);

	useEffect(() => {
		dispatch(fetchCustomerBlueprint(email, date));
	}, [email, date]);

	const isLoading = blueprintState.loading;
	const customerBlueprint = blueprintState.data;

	const stateCallback = (state) => {
		setDesignerState(state);
	};

	const designerOptions: Partial<DesignerOptionsState> = {
		assetsBaseUrl: process.env.REACT_APP_ASSETS_BASE_URL,
		assetsSasToken: process.env.REACT_APP_ASSETS_SAS_TOKEN,
		readOnly: true,
		autoZoom: true,
		contextMenu: false
	};

	const blueprintDesignerReady = async (bpDispatch) => {
		await handleDesignerView('hybrid');
		bpDispatch(DesignerActionTypes.SetBlueprint, blueprintState.data);
	};

	// populate island selection
	const getBlueprintIslands = (bp) => {
		console.log('getBlueprintIslands', bp);
		return bp && bp.islands.map((island, i) => {
			return <option value={i} key={i}>{island.sku}</option>;
		});
	};

	const handleDesignerView = async (view: string): Promise<void> => {
		switch (view) {
			case 'appliance':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.None);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 0);
				break;
			case 'hybrid':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.Outside);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 50);
				break;
			case 'cutout':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.Inside);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 100);
				break;
			default:
				break;
		}
	};

	return (isLoading || !blueprintState
		? <LoadingSpinner />
		: <>
			<Flex width="full" height="full" align="top" justifyContent="center" padding="0 0 0 0" position="relative" top="-20px">
				<Grid width="98%" templateRows="50px 1fr 50px" templateColumns="1fr 300px" position="relative" left="-25px">
					<GridItem rowStart={1} rowSpan={3}>
						{/* Designer Control Grid */}
						<Grid templateRows="50px 1fr 50px" templateColumns="50px 1fr 50px">
							<GridItem rowSpan={3} colSpan={1}>
								<Center height="100%">
									<div style={{ position: 'relative', left: 20 }}>
										<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint left"
											icon={<ChevronLeftIcon />}
											onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelLeft, null)} />
									</div>
								</Center>
							</GridItem>
							<GridItem>
								<div style={{ position: 'relative', top: 20 }}>
									<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint up"
										icon={<ChevronUpIcon />}
										onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelUp, null)} />
								</div>
							</GridItem>
							<GridItem rowSpan={3} width="50px">
								<Center height="100%">
									<div style={{ position: 'relative', right: 20 }}>
										<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint right"
											icon={<ChevronRightIcon />}
											onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelRight, null)} />
									</div>
								</Center>
							</GridItem>
							<GridItem minWidth="450px">
								<BlueprintDesigner
									onStateChange={stateCallback}
									onReady={blueprintDesignerReady}
									designerOptions={designerOptions} />
							</GridItem>
							<GridItem>
								<div style={{ position: 'relative', bottom: 20 }}>
									<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint down"
										icon={<ChevronDownIcon />}
										onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelDown, null)} />
								</div>
							</GridItem>
						</Grid>
					</GridItem>
					<GridItem rowStart={2}>
						<Box height="100%" alignItems="start" display="inline-block">
							{/* Blueprint Customer - Style - Metadata */}
							<Box paddingBottom={5} textAlign="left">
								<Flex>
									<Box paddingRight="5" as="button" onClick={onOpen} transition=".5s ease" borderColor="#ccd0d5">
										<Modal isOpen={isOpen} isCentered onClose={onClose}>
											<ModalOverlay />
											<ModalContent>
												<ModalHeader>Expanded View of Island</ModalHeader>
												<ModalCloseButton />
												<ModalBody>
													<Img src={`data:image/jpeg;base64,${blueprintState.data.image ?? ''}`} />
												</ModalBody>
											</ModalContent>
										</Modal>
										<Box display="block" _hover={{ opacity: 1 }} background="rgb(0, 0, 0)" fontSize="12px" color="#ffffff" width="100%" transition=".5s ease" opacity=".5" textAlign="center">
											<Image boxSize="100" src={`data:image/jpeg;base64,${customerBlueprint.image}`} width={'100px'} style={{ border: '1px rgb(50,50,50,0.1) solid' }} />
										</Box>
									</Box>
									{/* <Box>
										<Text fontSize="20px" color="#808080">{customerBlueprint.layout.replace(/"/gu, '').replace(/\\/gu, '')}</Text>
									</Box> */}
								</Flex>


								<Text fontSize="15px" color="#808080"><strong>Blueprint Id</strong>: {customerBlueprint.date}</Text>
								<Text fontSize="15px" color="#808080"><strong>Invoice #</strong>: {customerBlueprint.invoiceNumber}</Text>
								<Text fontSize="15px" color="#808080"><strong>Estimate #</strong>: {customerBlueprint.estimateNumber}</Text>
								<Text fontSize="15px" color="#808080"><strong>Work Order #</strong>: {customerBlueprint.workorderNumber}</Text>
							</Box>

							<Box display={'flex'} justifyContent={'start'} mb={2}>
								<Checkbox isDisabled isChecked={customerBlueprint.isPrimary}>
								Is selected for manufacturing
								</Checkbox>
							</Box>

							{/* Navigation */}
							<Box marginBottom={3} padding={2} textAlign="left" border="1px solid grey">
								<Text paddingBottom={3} fontSize="medium">Navigation</Text>

								<Stack spacing="3" width="100%" height="65%">
									<Box display="Flex" fontSize="small">
										<Text padding="10px" fontSize="small">Display:</Text>
										<Select placeholder="display mode" fontSize="small" defaultValue={designerState.navigation.displayMode}
											onChange={(e) => blueprintDesignerAction(NavigationActionTypes.SetDisplayMode, e.target.value)}>
											<option value={DisplayMode.Inline}>Inline</option>
											<option value={DisplayMode.Overhead}>Overhead</option>
											<option value={DisplayMode.Panel}>Panel</option>
										</Select>
									</Box>

									<Box display="Flex" fontSize="small">
										<Text padding="10px" fontSize="small">Island:</Text>
										<Select placeholder="select island to edit" fontSize="small" defaultValue={designerState.navigation.islandKey}
											onChange={(e) => blueprintDesignerAction(NavigationActionTypes.SelectIsland, e.target.value)}>
											{getBlueprintIslands(blueprintState.data.state[blueprintState.data.state.length - 1])}
										</Select>
									</Box>
								</Stack>
							</Box>
						</Box>

					</GridItem>
				</Grid>
			</Flex>
		</>);
};
