/* eslint-disable no-use-before-define */
import React from 'react';

// import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';
import ReactDOM from 'react-dom';
// import thunk from 'redux-thunk';

import { ColorModeScript } from '@chakra-ui/react';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { Provider } from 'react-redux';
// import { applyMiddleware, createStore } from 'redux';

import * as serviceWorker from './serviceWorker';
import { App } from './app';
import { Auth0ProviderWithHistory } from './components/higher-order/auth/auth0-provider-with-history';
import { BrowserRouter } from 'react-router-dom';
import { reportWebVitals } from './reportWebVitals';
// import { rootReducer } from './store/reducers/rootReducer';

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)));

// saga.run()

ReactDOM.render(
	<BrowserRouter>
		<Auth0ProviderWithHistory>
			{/* <Provider store={store}> */}
			<React.StrictMode>
				<ColorModeScript/>
				<App/>
			</React.StrictMode>
			{/* </Provider> */}
		</Auth0ProviderWithHistory>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
