/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
import {
	BlueprintData,
	BlueprintDesigner,
	blueprintDesignerAction,
	defaultPublicState,
	DesignerActionTypes,
	DesignerOptionActionTypes,
	DesignerOptionsState,
	Kitchen,
	NavigationActionTypes,
	PublicState,
	ShowDimensions
} from '@rta/rta-blueprint-component';
import {
	Button,
	Center,
	Flex,
	Grid,
	GridItem,
	IconButton,
	useDisclosure
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { BlueprintState } from '../../../store/blueprint/types';
import { CustomersState } from '../../../store/customers/types';
import { fetchCustomerBlueprint } from '../../../store/blueprint/fetch-blueprint.action';
import { fetchCustomers } from '../../../store/customers/fetch-customers.action';
import { LoadingSpinner } from '../../shared/loading-spinner/loading-spinner';
import { selectBlueprint } from '../../../store/blueprint/select-blueprint.selector';
import { selectCustomers } from '../../../store/customers/select-customers.selector';
import { addManufacturingBlueprint, saveBlueprint } from '../../../store/blueprint';

import '../../../styles/designer-page.scss';
import { BlueprintBase } from '@rta/rta-blueprint-component/lib/types/blueprint.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Designer = ({ match }: any): JSX.Element => {
	const { email, date } = match.params;

	const dispatch = useDispatch();
	const blueprintState: BlueprintState = useSelector(selectBlueprint);
	// const customersState: CustomersState = useSelector(selectCustomers);

	const history = useHistory();
	// const { isOpen, onOpen, onClose } = useDisclosure();

	// READ-ONLY -> this state is set through the designer tool
	const [designerState, setDesignerState] = useState<PublicState>(defaultPublicState);

	useEffect(() => {
		dispatch(fetchCustomerBlueprint(email, date));
		// dispatch(fetchCustomers(email));
	}, [email, date]);

	// const [customersList] = customersState.data;
	const customerBlueprint = blueprintState.data;
	// const isLoading = customersState.loading || blueprintState.loading || blueprintState.saving;
	const isLoading = blueprintState.loading || blueprintState.saving;

	const stateCallback = (state) => {
		// console.log('stateCallback', state);
		setDesignerState(state);
	};

	const saveCallback = (kitchen: Kitchen) => {
		console.log('saving your kitchen design...', kitchen);
		dispatch(saveBlueprint(kitchen));
	};

	const onDataChange = (oldData: Partial<BlueprintBase>, newData: Partial<BlueprintBase>): void => {
		console.log('kitchen data changed:', { newData, oldData });
		if (typeof newData.isPrimary !== 'undefined' && !oldData.isPrimary && newData.isPrimary) {
			dispatch(addManufacturingBlueprint());
		}
	};

	const designerOptions: Partial<DesignerOptionsState> = {
		assetsBaseUrl: process.env.REACT_APP_ASSETS_BASE_URL,
		assetsSasToken: process.env.REACT_APP_ASSETS_SAS_TOKEN,
		readOnly: false,
		autoZoom: true,
		contextMenu: true
	};

	const blueprintDesignerReady = async (bpDispatch) => {
		await handleDesignerView('hybrid');
		// bpDispatch(DesignerActionTypes.SetBlueprint, {
		// 	kitchen: customerBlueprint.state[customerBlueprint.state.length - 1],
		// 	metadata: customerBlueprint as BlueprintMetadata
		// });
		await bpDispatch(DesignerActionTypes.SetBlueprint, customerBlueprint);

		if (customerBlueprint.state.length > 0) {
			const currentState = customerBlueprint.state[customerBlueprint.state.length - 1];
			const defaultIsland = currentState.islands.find((island) => island.key === currentState.defaultIsland) || currentState.islands[0];
			console.log('UI Default Island', defaultIsland);
			if (defaultIsland.key) await blueprintDesignerAction(NavigationActionTypes.SelectIsland, defaultIsland.key);
		}
	};

	const handleDesignerView = async (view: string): Promise<void> => {
		switch (view) {
			case 'appliance':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.None);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 0);
				break;
			case 'hybrid':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.Outside);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 50);
				break;
			case 'cutout':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.Inside);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 100);
				break;
			case 'manufacturing':
				await blueprintDesignerAction(DesignerOptionActionTypes.SetDimensionView, ShowDimensions.Inside);
				await blueprintDesignerAction(DesignerOptionActionTypes.SetAppliaceOpacity, 0);
				break;
			default:
		}
	};
	return (isLoading
		? <LoadingSpinner />
		: <>
			<Flex alignItems="start" display="none">
				<Button zIndex={99} size="xs" margin="10px 0 0 10px" onClick={() => {
					history.push('/');
				}}>
					Back to list of customers
				</Button>
			</Flex>

			<Flex width="full" height="full" align="top" justifyContent="center" padding="0 0 0 0" position="relative"
				top="-20px">
				<Grid width="98%" templateRows="50px 1fr 50px" position="relative" left="-25px">
					<GridItem rowStart={1} rowSpan={3}>
						{/* Designer Control Grid */}
						<Grid templateRows="50px 1fr 50px" templateColumns="50px 1fr 50px">
							<GridItem rowSpan={3} colSpan={1}>
								<Center height="100%">
									<div style={{ position: 'relative', left: 20 }}>
										<IconButton size="lg" variant="solid" isRound={true}
											aria-label="rotate blueprint left"
											icon={<ChevronLeftIcon />}
											onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelLeft, null)} />
									</div>
								</Center>
							</GridItem>
							<GridItem>
								<div style={{ position: 'relative', top: 20 }}>
									<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint up"
										icon={<ChevronUpIcon />}
										onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelUp, null)} />
								</div>
							</GridItem>
							<GridItem rowSpan={3} width="50px">
								<Center height="100%">
									<div style={{ position: 'relative', right: 20 }}>
										<IconButton size="lg" variant="solid" isRound={true}
											aria-label="rotate blueprint right"
											icon={<ChevronRightIcon />}
											onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelRight, null)} />
									</div>
								</Center>
							</GridItem>
							<GridItem minWidth="450px">
								<BlueprintDesigner
									onStateChange={stateCallback}
									onReady={blueprintDesignerReady}
									onSave={saveCallback}
									onDataChange={onDataChange}
									designerOptions={designerOptions} />
							</GridItem>
							<GridItem>
								<div style={{ position: 'relative', bottom: 20 }}>
									<IconButton size="lg" variant="solid" isRound={true} aria-label="rotate blueprint down"
										icon={<ChevronDownIcon />}
										onClick={() => blueprintDesignerAction(NavigationActionTypes.ShiftPanelDown, null)} />
								</div>
							</GridItem>
						</Grid>
					</GridItem>
				</Grid>
			</Flex>
		</>);
};

