import { Blueprint } from '@rta/rta-blueprint-component';

export interface Customer {
	etag: string;
	partitionKey: string;
	rowKey: string;
	timestamp: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	numberOfDesigns: number;
	pathToMetadataFile: string;
	designs: Array<string>;
	blueprints: Array<Blueprint>;
}

export type GraphResponse = Record<string, Array<Customer>>;

export enum CustomersActionTypes {
	FETCH_REQUEST = '@@customer/FETCH_REQUEST',
	FETCH_SUCCESS = '@@customer/FETCH_SUCCESS',
	FETCH_ERROR = '@@customer/FETCH_ERROR',
	FETCH_DESIGNS_REQUEST = '@@customer/FETCH_DESIGNS_REQUEST',
	FETCH_DESIGNS_SUCCESS = '@@customer/FETCH_DESIGNS_SUCCESS',
	FETCH_DESIGNS_ERROR = '@@customer/FETCH_DESIGNS_ERROR',
	SELECTED = '@@customer/SELECTED',
}

export interface CustomersState {
	readonly loading: boolean;
	readonly data: Array<Customer>;
	readonly errors?: string;
}
