import { TextProps } from '@chakra-ui/layout';

export const style = {
	titleBox: {
		title: {
			fontSize: '30px',
			textTransform: 'uppercase',
			color: 'black',
			textAlign: 'center'
		} as TextProps,
		label: {
			fontSize: '18px',
			color: 'black',
			textAlign: 'right',
			lineHeight: '1.25em'
		} as TextProps
	}
};
