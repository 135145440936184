/* eslint-disable max-lines-per-function */
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { applyMiddleware, createStore } from 'redux';

import { createRootReducer } from '../../../store';

type WithApolloClientProps = {
	children: React.ReactNode
};

const WithApolloClient = ({ children }: WithApolloClientProps): JSX.Element => {
	// const errorLink = onError(({ graphQLErrors }) => {
	// 	if (graphQLErrors) {
	// 		graphQLErrors.map(({ message, path }) => console.error(message));
	// 	}
	// });

	const authLink = setContext((_x, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = localStorage.getItem('token');

		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : ''
			}
		};
	});

	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_API_URL
	});


	const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
		cache: new InMemoryCache(),
		link: authLink.concat(httpLink)
	});

	const store = createStore(
		createRootReducer(),
		// initialState,
		composeWithDevTools(applyMiddleware(
			thunk.withExtraArgument({ client: {
				query: (name, query, variables) => {
					return client
						.query({
							query,
							variables,
							fetchPolicy: 'no-cache'
						})
						.then(({ data }) => {
							return data[name];
						});
				},
				mutate: (name, mutation, variables) => {
					return client
						.mutate({
							mutation,
							variables
						})
						.then(({ data }) => data[name]);
				}
			}}),
			logger
		))
	);

	return (
		<ApolloProvider client={client}>
			<Provider store={store}>
				{children}
			</Provider>
		</ApolloProvider>
	);
};

export default WithApolloClient;
