/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

import { PutBlueprintUpdates } from '../../graph/mutations';
import { updateStateOnBlueprint } from './update-state-on-blueprint';
import { Blueprint, Kitchen } from '@rta/rta-blueprint-component';
import { BlueprintActionTypes, BlueprintState } from '.';

export const saveBlueprintRequest = () => action(BlueprintActionTypes.SAVE_BLUEPRINT_REQUEST);
export const saveBlueprintSuccess = (data: Blueprint) => action(BlueprintActionTypes.SAVE_BLUEPRINT_SUCCESS, data);
export const saveBlueprintError = (message: string) => action(BlueprintActionTypes.SAVE_BLUEPRINT_ERROR, message);

export const saveBlueprint = (kitchen: Kitchen) => (dispatch, getState, { client }) => {
	dispatch(saveBlueprintRequest());

	const blueprintState: BlueprintState = getState().blueprint;
	const tmpBlueprint = updateStateOnBlueprint(blueprintState.data, kitchen);
	// don't send image data, it's immutable
	delete tmpBlueprint.image;
	const tmpBlueprintString = JSON.stringify(tmpBlueprint);

	return client
		.mutate(
			'updateCustomerBlueprintOnBlob',
			PutBlueprintUpdates,
			{ email: blueprintState.data.email, blueprint: tmpBlueprintString, designDate: blueprintState.data.date }
		)
		.then((data: Blueprint) => {
			console.log('kitchen design saved...');
			return dispatch(saveBlueprintSuccess({ ...blueprintState.data, ...data }));
		})
		.catch((e) => dispatch(saveBlueprintError(e.message)));
};
