import { gql } from '@apollo/client';

export const GetCustomerDesigns = gql`
   query fuzzySearchCustomers($searchString: String!){
       fuzzySearchCustomers(searchString: $searchString){
           email
           designs
           blueprints {
               isPrimary
               image
               date
               layout
               estimateNumber
               invoiceNumber
               workorderNumber
           }
       }
}
`;
