import QRCode from 'qrcode.react';

import { assetMaps, getIslandDescription, groupAppliances } from '@rta/rta-blueprint-component';
import {
	Box,
	Flex,
	Text,
	VStack
} from '@chakra-ui/react';

import { PageHeader } from './pg-header';
import { PGProps } from '../print-types';
import { PageOrientation, Paper } from '../paper';

import '../../../../styles/designer-page.scss';

export const PGCover = (props: PGProps): JSX.Element => {
	const { customer, blueprint, qrData } = props;
	if (!blueprint || !customer) return <></>;

	const activeStateIndex = blueprint.state.length - 1;
	const state = blueprint.state[activeStateIndex];

	const applianceGrouping = activeStateIndex >= 0
		? groupAppliances(state.islands)
		: { appliances: {}};
	const applianceSkus = Object.keys(applianceGrouping.appliances) || [];
	const counterAsset = state.material.countertop ? assetMaps.countertopAssetMap[state.material.countertop] : { displayName: state.material.countertop };
	const counterDescription = counterAsset.displayName;

	return (
		<Paper orientation={ PageOrientation.landscape }>
			<VStack spacing={4} align="stretch" width="100%">
				<PageHeader blueprint={blueprint} customer={customer}/>

				<Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column" padding="30px 30px 0 30px">
					<Text fontSize="16px" color="black" textAlign="center"><strong>Selected Surround:</strong> <span style={{ textTransform: 'capitalize' }}>{state.material.surround?.split('-').join(' ')}</span></Text>
					<Text fontSize="16px" color="black" textAlign="center"><strong>Selected Countertop:</strong> <span style={{ textTransform: 'capitalize' }}>{counterDescription}</span></Text>
				</Flex>

				<Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column" padding="0 30px">
					{state.islands.map((el, key) => (
						<Text fontSize="16px" color="black" textAlign="center" key={key}><strong>Island Type:</strong> {getIslandDescription(el)}</Text>
					))}
				</Flex>
				<Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column" padding="0 30px">
					<Text fontSize="16px" color="black" textAlign="center"><strong>Appliance List:</strong> </Text>
					{applianceSkus.map((sku) => {
						return <Text fontSize="16px" color="black" textAlign="center" key={sku}>
							SKU: {sku}, Quantity: {applianceGrouping.appliances[sku].quantity}, Description: {applianceGrouping.appliances[sku].description}
						</Text>;
					})}
				</Flex>
				{/* <Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column" padding="0 30px">
					<Text fontSize="16px" color="black" textAlign="center"><strong>Non-Standard Changes:</strong> </Text>
				</Flex> */}
				<Box width="128px" height="128px" position="absolute" right="20px" bottom="20px">
					<QRCode level="H" value={JSON.stringify(qrData)}/>
				</Box>
			</VStack>
		</Paper>

	);
};
