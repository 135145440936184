/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { GetCustomerDesigns } from '../../graph/queries/get-customer-designs';
import { Customer, CustomersActionTypes } from './types';

export const fetchCustomerDesignsRequest = () => action(CustomersActionTypes.FETCH_DESIGNS_REQUEST);
export const fetchCustomerDesignsSuccess = (data: Array<Customer>) => action(CustomersActionTypes.FETCH_DESIGNS_SUCCESS, data);
export const fetchCustomerDesignsError = (message: string) => action(CustomersActionTypes.FETCH_DESIGNS_ERROR, message);

export const fetchCustomerDesigns = (searchString: string) => (dispatch, getState, { client }) => {
	dispatch(fetchCustomerDesignsRequest());

	// empty search string clears results
	if (searchString === '') {
		return new Promise((resolve) => {
			resolve(dispatch(fetchCustomerDesignsSuccess([])));
		});
	}

	return client
		.query(
			'fuzzySearchCustomers',
			GetCustomerDesigns,
			{ searchString }
		)
		.then((data: Array<Customer>) => dispatch(fetchCustomerDesignsSuccess(data)))
		.catch((e) => dispatch(fetchCustomerDesignsError(e.message)));
};
