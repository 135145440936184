import { combineReducers } from 'redux';

import { blueprintReducer, BlueprintState, blueprintStateInitialState } from './blueprint';
import { customersReducer, CustomersState, customersStateInitialState } from './customers';

export interface ApplicationState {
	customers: CustomersState,
	blueprint: BlueprintState
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = () => combineReducers({
	customers: customersReducer,
	blueprint: blueprintReducer
});

export const initialState: {customers: CustomersState, blueprint: BlueprintState } = {
	customers: customersStateInitialState,
	blueprint: blueprintStateInitialState
};
