import { useAuth0 } from '@auth0/auth0-react';
import {
	Box,
	ChakraProvider,
	Grid,
	theme
} from '@chakra-ui/react';

import { Footer } from './components/shared/footer/footer';
import { Header } from './components/shared/header/header';
import { LoadingSpinner } from './components/shared/loading-spinner/loading-spinner';

import Routing from './config/routing/routing';
import WithApolloClient from './components/higher-order/apollo/withApollo';

export const App = (): JSX.Element => {
	const auth0 = useAuth0();
	const { isLoading } = auth0;

	if (auth0.isAuthenticated) {
		auth0.getAccessTokenSilently().then((data) => localStorage.setItem(
			'token',
			data
		));
	}

	return (
		<WithApolloClient>
			<ChakraProvider theme={theme}>
				<Box textAlign="center" fontSize="xl">
					<Grid minH="100vh">
						<Header/>
						<Box minH="calc(100vh - 145px)">
							{isLoading ? <LoadingSpinner/> : <Routing/>}
						</Box>
						<Footer/>
					</Grid>
				</Box>
			</ChakraProvider>
		</WithApolloClient>
	);
};

