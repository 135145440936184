/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
import QRCode from 'qrcode.react';
import ReactShadowRoot from 'react-shadow-root';
import {
	Box,
	Flex,
	Img,
	Text,
	VStack
} from '@chakra-ui/react';

import { PGProps } from '../print-types';
import { PrintLogo } from '../../../shared/print-logo/print-logo';
import { PageOrientation, Paper } from '../paper';

import '../../../../styles/designer-page.scss';
import {
	BlueprintDesigner,
	DesignerActionTypes,
	DesignerOptionsState,
	DisplayMode,
	NavigationActionTypes,
	ShowDimensions
} from '@rta/rta-blueprint-component';

export const PGCrateSheet = (props: PGProps): JSX.Element => {
	const { blueprint, customer, designerOptions } = props;
	const activeStateIndex = blueprint.state.length - 1;
	const state = blueprint.state[activeStateIndex];

	const frontViewOptions: Partial<DesignerOptionsState> = {
		...designerOptions,
		showDimensions: ShowDimensions.None,
		showTitle: false,
		blueprintToApplianceView: 1,
		autoZoom: false,
		zoomFactor: 3.0
	};

	return <>{
		state.islands.map((island, islandIndex) => {
			return <Paper key={islandIndex} orientation={ PageOrientation.landscape }>
				<VStack spacing={4} align="stretch" width="100%">
					<Box margin="35px 0 0 0">
						<Text fontSize="50px" textTransform="uppercase" fontWeight="bold" color="black">Crate Contents</Text>
					</Box>
					<Flex justifyContent="center" marginTop="-0">
						<Box width="calc(100% - 350px)" height="280px">
							<ReactShadowRoot>
								<BlueprintDesigner designerOptions={frontViewOptions} onReady={(bpDispatch) => {
									bpDispatch(NavigationActionTypes.SetDisplayMode, DisplayMode.Panel);
									bpDispatch(NavigationActionTypes.SelectIsland, islandIndex);
									bpDispatch(DesignerActionTypes.SetBlueprint, blueprint);
								}} />
							</ReactShadowRoot>
						</Box>
						{/* <Img src={`data:image/jpeg;base64,${blueprint.image}`} height="280px" /> */}
					</Flex>
					<Box margin="10px 0">
						<Text fontSize="25px" textTransform="uppercase" color="black" textAlign="center"><strong>{blueprint.workorderNumber}, {customer.firstName} {customer.lastName}</strong></Text>
					</Box>
					<Box>
						<Text fontSize="30px" textTransform="uppercase" color="black" textAlign="center">{island.sku}</Text>
						<Text fontSize="30px" textTransform="uppercase" color="black" textAlign="center"><strong>Finish:</strong> {state.material.surround?.split('-').join(' ')}</Text>
						<Text fontSize="30px" textTransform="uppercase" color="black" textAlign="center"><strong>Conuntertop:</strong> Concrete - {state.material.countertop}</Text>
					</Box>
					<Flex>
						<Box width="90%" margin="0px auto" border="5px solid #3bc7f3">
							<Text fontSize="30px" textTransform="uppercase" color="black" textAlign="center">This crate contains island surround and countertop only.</Text>
						</Box>
					</Flex>
					<Flex alignItems="center" justifyContent="center" flexDirection="column" position="absolute" left="25" top="0">
						<PrintLogo />
					</Flex>
					<Box position="absolute" right="25" top="0">
						<Flex alignItems="center" justifyContent="center" flexDirection="column">
							<Text fontSize="12px" textTransform="uppercase" color="black" textAlign="center">Manufacturing</Text>
							<Text fontSize="12px" textTransform="uppercase" color="black" textAlign="center">151 S. Green Street</Text>
							<Text fontSize="12px" textTransform="uppercase" color="black" textAlign="center">Cambridge City, in 47327</Text>
							<Text fontSize="12px" textTransform="uppercase" color="black" textAlign="center">(800) 827-1830</Text>
						</Flex>
					</Box>
					<Box width="128px" height="128px" position="absolute" right="20px" bottom="165px">
						<QRCode level="L" value={'https://youtu.be/HoVpjj_wHHk'}/>
					</Box>
				</VStack>
			</Paper>;
		})
	}</>;
};
