import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@chakra-ui/react';

export const LoginButton = (): JSX.Element => {
	const { loginWithRedirect } = useAuth0();

	return <Box>
		<Button type="submit" variant="outline" color="#41B6E0" fontSize={['12px', '18px', '18px', '18px']}
			onClick={() => {
				loginWithRedirect({
					audience: process.env.REACT_APP_AUTH0_AUDIENCE
				});
			}}>Log In</Button>
	</Box>;
};
