import {
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr
} from '@chakra-ui/react';

type ITableCommon = {
	head: Array<{ name: string }>;
	cell: Array<{ cellClass?: string; render: (sku) => JSX.Element}>;
	// eslint-disable-next-line @typescript-eslint/ban-types
	appliances: {};
  }

export const TableCommon = (props: ITableCommon) => {
	const { head, cell, appliances } = props;
	return (
		<Table size="lg" className={'table'}>
			<Thead>
				<Tr>
					{head.map(({ name }, key) => (
						<Th key={key}>{name}</Th>
					))}
				</Tr>
			</Thead>
			<Tbody>
				{ Object.keys(appliances).map((sku, index) => {
					return <Tr key={index}>
						{
							cell.map(({ cellClass, render }, key) => {
								return <Td className={cellClass ? cellClass : ''} key={key}>{render(sku)}</Td>;
							})
						}
					</Tr>;
				})}
			</Tbody>
		</Table>
	);
};
