import { gql } from '@apollo/client';

export const GetCustomerBlueprint = gql`
   query getCustomerBlueprintToView($email: String!, $designName: String!){
       getCustomerBlueprintToView(email: $email, designName: $designName){
           email
           date
		   schemaVersion
           image
           layout
           islands
           surround
           counter
           state
           estimateNumber
           invoiceNumber
           workorderNumber
           isPrimary
      }
}
`;


// export const getCustomerBlueprint = (email: string, designName: string) => (dispatch, _, { client }) => {
// 	dispatch(setRequestInProgress(true));

// 	return client
// 		.query<CreateOrganizationPayload, Organization>('createOrganization', GetCustomerBlueprint, { email, designName })
// 		.then((data) => dispatch(SetCustomerBlueprintAction(data)))
// 		.catch((e) => dispatch(setCustomerBLueprintErrorAction(e)))
// 		.finaly(() => dispatch(setRequestInProgress(false)));
// };
