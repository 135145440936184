import { Route, Switch } from 'react-router-dom';

import { Customers } from '../../components/pages/customers/customers';
import { Designer } from '../../components/pages/designer/designer';
import { Print } from '../../components/pages/print/manufacturing/print';
import { ProtectedRoute } from '../../components/higher-order/auth/with-admin-guard';
import { View } from '../../components/pages/view/view';

const Routing = (): JSX.Element => {
	return (
		<Switch>
			<Route path="/blueprint/view/:email/:date" component={View}/>
			<Route path="/blueprint/print/:email/:date" component={Print}/>
			<ProtectedRoute path="/blueprint/edit/:email/:date" component={Designer}/>
			<ProtectedRoute path="/blueprint/find/:email" component={Customers}/>
			<ProtectedRoute path="/" exact component={Customers}/>
		</Switch>
	);
};

export default Routing;
