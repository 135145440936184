import { Blueprint, Kitchen } from '@rta/rta-blueprint-component';

export const updateStateOnBlueprint = (blueprint: Blueprint, newState: Kitchen): Blueprint => {
	if (typeof blueprint.state === 'string') blueprint.state = [];

	// console.log('the state', blueprint.state);
	blueprint.state = [...blueprint.state, newState];
	// console.log('updated state', ...blueprint.state);

	return { ...blueprint };
};
