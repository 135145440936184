import QRCode from 'qrcode.react';
import { useState } from 'react';
import {
	BlueprintDesigner,
	DesignerActionTypes,
	DesignerOptionsState,
	DisplayMode,
	NAVIGATION_PANEL_LABELS,
	NavigationActionTypes,
	ShowDimensions
} from '@rta/rta-blueprint-component';
import {
	Box,
	Text,
	VStack
} from '@chakra-ui/react';

import { PagePanelHeader } from './pg-panel-header';
import { PGProps } from '../print-types';
import { PageOrientation, Paper } from '../paper';

import '../../../../styles/designer-page.scss';
import '../print.scss';

type PGPanelProps = PGProps & {
	islandKey: string;
	panelIndex: number;
}

export const PGPanel = (props: PGPanelProps): JSX.Element => {
	const { blueprint, designerOptions, qrData, panelIndex, islandKey } = props;
	const activeStateIndex = blueprint.state.length - 1;
	const island = blueprint.state[activeStateIndex].islands.find((fIsland) => fIsland.key === islandKey) || null;

	const [bpdState, setBpdState] = useState({} as unknown);

	const panelOptions: Partial<DesignerOptionsState> = {
		...designerOptions,
		showDimensions: ShowDimensions.Inside,
		blueprintToApplianceView: 100,
		autoZoom: true,
		dimensions: { fontSizeFactor: 1 }
	};

	if (!island) return <></>;

	const configurePanel = async (bpDispatch) => {
		await bpDispatch(NavigationActionTypes.SetDisplayMode, DisplayMode.Panel);
		await bpDispatch(NavigationActionTypes.SelectIsland, islandKey);
		await bpDispatch(NavigationActionTypes.SelectPanel, panelIndex);
		await bpDispatch(DesignerActionTypes.SetBlueprint, blueprint);
	};

	return <>
		<Paper orientation={ PageOrientation.landscape } key={panelIndex}>
			<VStack	spacing={0} align="stretch" width="100%">
				<PagePanelHeader blueprint={blueprint} { ...{ islandKey, panelIndex }} designerOptions={designerOptions}/>
				<Box height=".40in" margin=".075in">
					<Text fontSize=".25in" textTransform="uppercase" color="black" textAlign="center">
						<strong>{island.sku}: {NAVIGATION_PANEL_LABELS[panelIndex]}</strong>
					</Text>
				</Box>
				<Box height="4.75in" width="26.8cm" margin="0">
					<BlueprintDesigner
						designerOptions={panelOptions}
						onStateChange={setBpdState}
						onReady={(bpDispatch) => { configurePanel(bpDispatch); }}
					/>
				</Box>
				<Box className="qrcode-bottom-right">
					<QRCode level="Q" value={JSON.stringify({
						...qrData,
						blueprint: {
							...qrData.blueprint, islandKey, panelIndex
						}
					})}/>
				</Box>
			</VStack>
		</Paper>
	</>;
};
