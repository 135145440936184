import { gql } from '@apollo/client';

export const PutBlueprintUpdates = gql`
    mutation updateCustomerBlueprintOnBlob($email: String!, $designDate: String!, $blueprint: String!){
        updateCustomerBlueprintOnBlob(email: $email, designDate: $designDate, blueprint: $blueprint){
            date
            email
            estimateNumber
            invoiceNumber
            workorderNumber
        }
    }
`;
