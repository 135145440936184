import moment from 'moment';

import { Blueprint } from '@rta/rta-blueprint-component';
import { Box, Flex, Img, Text } from '@chakra-ui/react';

import { Customer } from '../../../../store/customers/types';
import { style } from './component.styles';

export type PageHeaderProps = {
	blueprint: Blueprint;
	customer: Customer;
	minimized?: boolean;
	subtitle?: string;
};

export const PageHeader = (props: PageHeaderProps): JSX.Element => {
	const {
		blueprint: {
			image, invoiceNumber, workorderNumber, date: blueprintStateDataDate
		},
		customer,
		minimized = false,
		subtitle = ''
	} = props;

	if (!props.blueprint || !props.customer) return <></>;

	return minimized
		? <>
			<Flex justifyContent="right" padding="10px">
				<Box width="350px">
					<Flex border="1px solid black" flexDirection="column">
						<Box width="100%">
							<Text { ...style.titleBox.title }><strong>{workorderNumber}</strong></Text>
						</Box>
						<Text { ...style.titleBox.label }>{subtitle}</Text>
					</Flex>
				</Box>
			</Flex>
		</>
		: <>
			<Flex justifyContent="space-between" padding="10px">
				<Img src={`data:image/jpeg;base64,${image}`} width="calc(100% - 300px)" height="190px" objectFit="cover" />
				<Box width="350px">
					<Flex border="1px solid black" flexDirection="column">
						<Box width="100%">
							<Text { ...style.titleBox.title }><strong>{workorderNumber}</strong></Text>
						</Box>
						<Box width="100%" borderTop="1px solid black" padding="5px">
							<Text { ...style.titleBox.label }>Invoice: {invoiceNumber}</Text>
							<Text { ...style.titleBox.label }>Sales Order: {workorderNumber}</Text>
							<Text { ...style.titleBox.label }>Customer: {customer.firstName} {customer.lastName}</Text>
							<Text { ...style.titleBox.label }>BP Contact: &lt;none&gt;</Text>
							<Text { ...style.titleBox.label }>Sales Rep: &lt;none&gt;</Text>
							<Text { ...style.titleBox.label }>Last Revision: {moment(Number(blueprintStateDataDate)).format('MM/DD/YYYY hh:mm:ss')}</Text>
							<Text { ...style.titleBox.label }>Print Date: {moment(Number(new Date().getTime())).format('MM/DD/YYYY hh:mm:ss')}</Text>
						</Box>
					</Flex>
				</Box>
			</Flex>
		</>;
};
