import { Flex } from '@chakra-ui/react';

import { PageOrientation } from '.';

import '../print.scss';

type PaperProps = {
	children: React.ReactNode;
	class?: string;
	orientation?: PageOrientation;
};

export const Paper = ({ orientation = PageOrientation.portrait, children, class: pageClass = '' }: PaperProps): JSX.Element => {
	const pageDimensions = orientation === PageOrientation.landscape
		? { width: '11in', height: '7.25in' }
		: { width: '8.5in', height: '10in' };

	const pageOrientationClass = orientation === PageOrientation.landscape ? 'page-landscape' : 'page-portrait';
	return <>
		<Flex
			className={ `page ${pageOrientationClass} ${pageClass}` }
			{ ...pageDimensions }
			// pageBreakAfter="always"
			// pageBreakBefore="always"
		>
			{children}
		</Flex>
	</>;
};

Paper.displayName = 'Paper';
