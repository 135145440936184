import { gql } from '@apollo/client';

export const AddManufacturingBlueprint = gql`
    mutation addManufacturingBlueprint($email: String!, $blueprintId: String!){
        addManufacturingBlueprint(email: $email, blueprintId: $blueprintId){
            blueprintId
            email
        }
    }
`;
