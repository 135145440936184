/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

import { Blueprint } from '@rta/rta-blueprint-component';
import { PutBlueprintUpdates } from '../../graph/mutations';
import { BlueprintActionTypes, BlueprintState } from '.';

export const updateCustomerBlueprintRequest = () => action(BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_REQUEST);
export const updateCustomerBlueprintSuccess = (data: Blueprint) => action(BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_SUCCESS, data);
export const updateCustomerBlueprintError = (message: string) => action(BlueprintActionTypes.UPDATE_CUSTOMER_BLUEPRINT_ERROR, message);

export const updateCustomerBlueprint = (blueprint) => (dispatch, getState, { client }) => {
	dispatch(updateCustomerBlueprintRequest());

	const blueprintState: BlueprintState = getState().blueprint;
	const tmpBlueprint = { ...blueprintState.data, ...blueprint };
	// don't send image data, it's immutable
	delete tmpBlueprint.image;
	const tmpBlueprintString = JSON.stringify(tmpBlueprint);

	return client
		.mutate(
			'updateCustomerBlueprintOnBlob',
			PutBlueprintUpdates,
			{ email: blueprintState.data.email, blueprint: tmpBlueprintString, designDate: blueprintState.data.date }
		)
		.then((data: Blueprint) => {
			return dispatch(updateCustomerBlueprintSuccess(data));
		})
		.catch((e) => dispatch(updateCustomerBlueprintError(e.message)));
};
