import ReactShadowRoot from 'react-shadow-root';
import {
	Blueprint,
	BlueprintDesigner,
	DesignerActionTypes,
	DesignerOptionsState,
	DisplayMode,
	NavigationActionTypes,
	ShowDimensions
} from '@rta/rta-blueprint-component';
import { Box, Flex, Text } from '@chakra-ui/react';

import { style } from './component.styles';

export type PagePanelHeaderProps = {
	blueprint: Blueprint;
	islandKey: string;
	panelIndex: number;
	designerOptions: DesignerOptionsState;
};

export const PagePanelHeader = (props: PagePanelHeaderProps): JSX.Element => {
	const { blueprint, islandKey, designerOptions } = props;
	const { workorderNumber } = blueprint;

	if (!props.blueprint) return <></>;

	const frontViewOptions: Partial<DesignerOptionsState> = {
		...designerOptions,
		showDimensions: ShowDimensions.None,
		showTitle: false,
		blueprintToApplianceView: 1,
		autoZoom: true
	};

	const topViewOptions: Partial<DesignerOptionsState> = {
		...designerOptions,
		showDimensions: ShowDimensions.None,
		showTitle: false,
		blueprintToApplianceView: 100,
		autoZoom: false,
		zoomFactor: 2.0
	};

	return <>
		<Flex justifyContent="space-between" padding="0 .25in" margin="0">
			<Box width="calc(100% - 350px)" height="2.5in">
				<ReactShadowRoot>
					<BlueprintDesigner designerOptions={frontViewOptions} islandKey={islandKey} onReady={(bpDispatch) => {
						bpDispatch(NavigationActionTypes.SetDisplayMode, DisplayMode.Panel);
						bpDispatch(DesignerActionTypes.SetBlueprint, blueprint);
					}} />
				</ReactShadowRoot>
			</Box>
			<Box width="350px" height="2.5in">
				<Flex border="1px solid black" flexDirection="column">
					<Box width="100%" height=".5in">
						<Text { ...style.titleBox.title }><strong>{workorderNumber}</strong></Text>
					</Box>
					<Box width="100%" height="2in" borderTop="1px solid black" padding=".05in">
						<BlueprintDesigner designerOptions={topViewOptions} islandKey={islandKey} onReady={(bpDispatch) => {
							bpDispatch(NavigationActionTypes.SetDisplayMode, DisplayMode.Overhead);
							bpDispatch(DesignerActionTypes.SetBlueprint, blueprint);
						}}/>
					</Box>
				</Flex>
			</Box>
		</Flex>
	</>;
};
