import { Image } from '@chakra-ui/react';
import logo from '../../../assets/images/logo.png';

export const PrintLogo = (): JSX.Element => <Image
	display="inline-block"
	margin="0 0 0 0"
	htmlWidth="200px"
	htmlHeight="50px"
	objectFit="cover"
	src={ logo }
	alt="RTA Outdoor Solutions Logo"
/>;
