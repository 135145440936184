import { Blueprint, GroupAppliances } from '@rta/rta-blueprint-component';
import {
	Box,
	Flex,
	Text,
	VStack
} from '@chakra-ui/react';

import { Customer } from 'src/store/customers/types';
import { PageHeader } from './pg-header';
import { TableCommon } from './table-common';
import { PageOrientation, Paper } from '../paper';

import '../../../../styles/designer-page.scss';

type PGAppliancePageProps = {
	pageNum: number;
	applianceGrouping: GroupAppliances
	blueprint: Blueprint;
	customer: Customer;
	renderHead: Array<{ name: string }>;
	renderCell: Array<{ cellClass?: string; render: (sku) => JSX.Element}>;
}

export const appliancePage = ({ pageNum, applianceGrouping, renderHead, renderCell, blueprint, customer }: PGAppliancePageProps): JSX.Element => {
	const applianceSkus = Object.keys(applianceGrouping.appliances).slice(5 * pageNum, 5);
	const appliances = {};
	applianceSkus.forEach((sku) => { appliances[sku] = applianceGrouping.appliances[sku]; });

	return <>
		<Paper orientation={ PageOrientation.landscape }>
			<VStack spacing={4}	align="stretch" width="100%">
				<PageHeader blueprint={blueprint} customer={customer} minimized={true}/>
				<Box margin="10px 0">
					<Text fontSize="25px" textTransform="uppercase" color="black" textAlign="center"><strong>Appliances</strong></Text>
				</Box>
				<Flex justifyContent="center" alignItems="flex-start" flexDirection="row" padding="0 30px">
					<TableCommon head={renderHead} cell={renderCell} appliances={appliances} />
				</Flex>
			</VStack>
		</Paper>
	</>;
};
