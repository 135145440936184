import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@chakra-ui/react';

export const LogoutButton = (): JSX.Element => {
	const { logout } = useAuth0();

	return <Box>
		<Button type="submit" variant="outline" color="#41B6E0" fontSize={['12px', '18px', '18px', '18px']}
			onClick={() => {
				logout({ returnTo: window.location.origin });
				localStorage.removeItem('token');
			}}>
            Log Out </Button>
	</Box>;
};
